<template>
    <div
        :id="`comment-${comment.id}`"
        class="comment"
        :class="{
            'are-replies-expanded' : isReplyExpanded,
            'is-reply' : isReply,
            'highlight-comment' : hightlightComment
        }"
    >
        <div class="user-picture-container">
            <router-link :to="{ name: 'web-app-profile', params: { displayName: `${comment.user.displayname}` } }">
                <user-picture :picture="comment.user.photo.url" />
            </router-link>
        </div>
        <div class="comment-content">
            <div class="comment-header">
                <router-link :to="{ name: 'web-app-profile', params: { displayName: `${comment.user.displayname}` } }" class="link-to-user-profile">
                    <user-name :name="`${comment.user.firstname} ${comment.user.lastname}`" />
                </router-link>
                <v-popover
                    class="more-options"
                >
                    <div class="tooltip-target">
                        <img src="@assets/img/icons/three-dots.svg" alt="More options">
                    </div>
                    <template v-if="isCommentAuthor" slot="popover">
                        <div class="popover-options">
                            <div v-close-popover class="popover-option" @click="handleEditCommentBox">
                                <span>Edit</span>
                                <img
                                    src="@assets/img/icons/edit.svg"
                                    class="edit"
                                    alt="Edit Comment"
                                >
                            </div>
                            <div v-close-popover class="popover-option" @click="deleteComment">
                                <span>Delete</span>
                                <img
                                    src="@assets/img/icons/trash-can.svg"
                                    class="delete"
                                    alt="Delete Comment"
                                >
                            </div>
                        </div>
                    </template>
                    <template v-else slot="popover">
                        <div class="popover-options">
                            <report-button :entity-id="Number(comment.id)" entity-name="comment" class="popover-option" />
                        </div>
                    </template>
                </v-popover>
            </div>
            <div class="comment-text">
                <p v-if="!isEditing" v-html="formattedCommentString" />
                <template v-else>
                    <div class="edit-comment-box">
                        <form
                            novalidate
                            @submit.prevent="editComment"
                            @keyup.ctrl.enter="editComment"
                        >
                            <textarea ref="editCommentText" v-model="commentString" rows="5" />
                            <div class="edit-actions">
                                <button type="button" class="btn cancel" @click="cancelEdit">
                                    Cancel
                                </button>
                                <button class="btn btn-white">
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </template>
            </div>
            <div class="comment-footer">
                <div class="left-side-footer">
                    <span class="comment-created-time">{{ comment.posted_moment }}</span>
                    <transition name="slide-right">
                        <span v-if="isEdited" class="ml-1">(edited)</span>
                    </transition>
                    <button type="button" class="comment-reply" @click="handleReplyBox">
                        Reply
                    </button>
                </div>
                <div class="right-side-footer">
                    <div class="like-button" :class="{ 'is-liked' : comment.is_liked }" @click="toggleLike">
                        <span v-if="comment.total_likes">{{ comment.total_likes }}</span>
                        <img
                            :src="require(`@assets/img/icons/heart-${ comment.is_liked ? 'full' : 'empty' }.svg`)"
                            class="like"
                            alt="Like"
                        >
                    </div>
                </div>
            </div>
            <transition name="slide-fade">
                <div v-show="showReplyBox" class="comment-reply-box">
                    <form
                        novalidate
                        @submit.prevent="replyToComment"
                        @keyup.ctrl.enter="replyToComment"
                    >
                        <textarea ref="replyBox" v-model="commentReply" rows="5" />
                        <div class="reply-box-actions">
                            <button type="button" class="btn cancel" @click="cancelReply">
                                Cancel
                            </button>
                            <button class="btn btn-white">
                                Post
                            </button>
                        </div>
                    </form>
                </div>
            </transition>
            <button
                v-if="totalReplies"
                type="button"
                class="replies-toggle"
                @click="toggleReply"
            >
                {{ isReplyExpanded ? "Hide all" : `View ${totalReplies}` }} replies
            </button>
            <transition name="replies-animation">
                <div v-if="isReplyExpanded" class="replies-list">
                    <transition-group name="slide-left" mode="out-in">
                        <comment-component
                            v-for="reply in replies"
                            :key="reply.id"
                            :comment="reply"
                            :memod-id="memodId"
                            is-reply
                            @add-reply="addReply"
                            @added-reply="addReplyToList"
                            @toggle-like="toggleLikeOnList"
                            @deleted-comment="deleteReplyFromList"
                            @update-total-comments-counter="(number) =>$emit('update-total-comments-counter', number)"
                        />
                    </transition-group>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
    name: "Comment",
    components: {
        UserPicture: () => import(/* webpackChunkName: "user-picture" */ "@/components/atoms/user-picture"),
        UserName: () => import(/* webpackChunkName: "user-name" */ "@c/atoms/user-name"),
        CommentComponent: () => import(/* comment-component */ "./comment"),
        ReportButton: () => import(/* webpackChunkName: "report-button" */ "@c/molecules/report/report-button")
    },
    props: {
        comment: {
            type: Object,
            required: true
        },
        memodId: {
            type: Number,
            required: true
        },
        isReply: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            hightlightComment: false,
            commentReply: "",
            replies: [],
            showReplyBox: false,
            isReplyExpanded: false,
            totalReplies: 0,
            isEditing: false,
            commentString: "",
            originalCommentString: "",
            isEdited: false
        };
    },
    computed: {
        ...mapGetters({
            isLoggedIn: "User/isLoggedIn"
        }),
        ...mapState({
            userData: state => state.User.data
        }),
        isCommentAuthor() {
            return Number(this.userData.id) === Number(this.comment.user.id);
        },
        formattedCommentString() {
            // eslint-disable-next-line no-control-regex
            let message = this.commentString;
            const entersRegexp = new RegExp(/(\r\n|\r|\n)/g);
            const mentionsHashesRegexp = new RegExp(/([@#])([\w-]+)/g);
            const matches = message.matchAll(mentionsHashesRegexp);
            const replaced = [];
            const links = {
                "@": "/{user}/memos/published",
                "#": "/topics/{tag}"
            };
            for (const match of matches) {
                const [find, type, text] = match;
                if (!replaced.includes(find)) {
                    replaced.push(find);
                    const link = links[type].replace(/\{(user|tag)\}/, text);
                    message = message.replaceAll(find, `<a href="${link}">${find}</a>`);
                }
            }
            message = message.replace(entersRegexp, "<br>");
            return message;
        }
    },
    created() {
        if (!this.isReply) {
            this.totalReplies = this.comment.total_replies;
        }
        this.isEdited = Boolean(this.comment.is_edited);
        this.commentString = this.comment.comment;
        this.originalCommentString = this.comment.comment;
    },
    mounted() {
        this.handleAnchorComment();
    },
    methods: {
        toggleReply() {
            if (!this.replies.length) {
                this.fetchCommentReplies();
            } else {
                this.isReplyExpanded = !this.isReplyExpanded;
            }
        },
        fetchCommentReplies() {
            axios.get(`/feeds/${this.$route.params.id}/comments/${this.comment.id}?sort=created_at|asc&limit=10&page=1`).then(({ data }) => {
                this.replies = data;
                if (this.replies.length) {
                    this.isReplyExpanded = !this.isReplyExpanded;
                }
            }).catch((error) => {
                console.log(error.response.data.message);
            })
        },
        cancelReply() {
            this.showReplyBox = false;
            this.commentReply = "";
        },
        handleReplyBox() {
            if (!this.isLoggedIn) {
                this.$modal.show("auth-modal", { allowClose: true, showSignIn: true });
                return;
            }

            if (!this.replies.length && !this.isReply) {
                this.fetchCommentReplies();
            }

            if (this.isReply) {
                this.commentReply = `@${this.comment.user.displayname} `
            }

            this.showReplyBox = true;

            const WAIT_ANIMATION_TIME = 500;
            setTimeout(() => {
                this.$refs.replyBox.focus();
            }, WAIT_ANIMATION_TIME);
        },
        replyToComment() {
            if (!this.commentReply.trim()) {
                return;
            }

            if (this.isReply) {
                this.$emit("add-reply", this.commentReply);
                this.showReplyBox = false;
                this.commentReply = "";
            } else {
                this.addReply(this.commentReply);
            }
        },
        addReply(commentReply) {
            console.log("AAA")
            axios.post(`/feeds/${this.memodId}/comments/${this.comment.id}`, { comment: commentReply }).then(({ data }) => {
                if (this.isReply) {
                    this.$emit("added-reply", data);
                } else {
                    this.showReplyBox = false;
                    this.commentReply = "";
                    this.totalReplies = this.totalReplies + 1;
                    this.replies.push(data);
                    this.isReplyExpanded = true;
                }

                this.$amplitude.logEvent("Replied", {
                    entity_id: this.memodId,
                    event_name: "Reply",
                    type: "Created"
                });

                console.log({
                    entity_id: this.memodId,
                    event_name: "Comment",
                    type: "Created"
                })

                this.$emit("update-total-comments-counter", 1);
            }).catch((error) => {
                console.log(error.response.data.errors.message);
            })
        },
        addReplyToList(newReply) {
            this.replies.push(newReply);
        },
        deleteComment() {
            axios.delete(`/feeds/${this.memodId}/comments/${this.comment.id}`).then(() => {
                this.$notify({
                    group: "app-notifications",
                    type: "success",
                    text: "Comment deleted",
                    duration: 3000
                });

                this.$emit("deleted-comment", this.comment.id);
                this.$emit("update-total-comments-counter", -(this.totalReplies + 1));
            }).catch((error) => {
                console.log(error.response.data.errors.message);
            })
        },
        deleteReplyFromList(commentId) {
            const deletedReplyIndex = this.replies.findIndex((reply) => reply.id == commentId);
            this.replies.splice(deletedReplyIndex, 1);
            this.totalReplies = this.totalReplies - 1;

            if (!this.isReply && !this.totalReplies) {
                this.isReplyExpanded = false;
            }
        },
        handleEditCommentBox() {
            this.isEditing = true;
            const WAIT_ANIMATION_TIME = 100;
            setTimeout(() => {
                this.$refs.editCommentText.focus();
            }, WAIT_ANIMATION_TIME);
        },
        editComment() {
            this.isEditing = false;

            axios.put(`/feeds/${this.memodId}/comments/${this.comment.id}`, { comment: this.commentString }).then(({ data }) => {
                this.$notify({
                    group: "app-notifications",
                    type: "success",
                    text: "Comment edited",
                    duration: 3000
                });

                this.originalCommentString = data.comment;
                this.isEdited = Boolean(data.is_edited);
            }).catch((error) => {
                console.log(error.response.data.errors.message);
            })
        },
        cancelEdit() {
            this.commentString = this.originalCommentString;
            this.isEditing = false;
        },
        toggleLike() {
            if (!this.isLoggedIn) {
                this.$modal.show("auth-modal", { allowClose: true, showSignIn: true });
                return;
            }

            axios.post(`/feeds/${this.memodId}/comments/${this.comment.id}/like`).then(({ data }) => {
                this.$emit("toggle-like", this.comment.id, data);
            }).catch((error) => {
                console.log(error.response.data.errors.message);
            })
        },
        toggleLikeOnList(commentId, data) {
            const toggleLikeReplyIndex = this.replies.findIndex((reply) => reply.id == commentId);
            this.replies[toggleLikeReplyIndex].is_liked = !this.replies[toggleLikeReplyIndex].is_liked;
            this.replies[toggleLikeReplyIndex].total_likes = data.total;
        },
        handleAnchorComment() {
            let anchorType = "comment";

            if (this.$route.hash.includes("reply")) {
                anchorType = "reply";

                if (this.$route.hash.includes(`comment-${String(this.comment.id)}`)) {
                    this.toggleReply();
                }
            }

            const urlHash = this.$route.hash;
            const commentHash = `${anchorType}-${this.comment.id}`;

            if (urlHash.includes(commentHash)) {
                const el = this.$el;

                const intersectionObserver = new IntersectionObserver((entries) => {
                    const [entry] = entries;
                    if (entry.isIntersecting) {
                        this.hightlightComment = true;
                    }
                });

                intersectionObserver.observe(el);

                el.scrollIntoView();
                el.focus({ preventScroll: true });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.comment {
    display: flex;
    padding: 35px 0;

    &-content {
        padding-top: 7.5px;
        flex: 1;

        .comment-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px;

            .link-to-user-profile {
                color: white;

                /deep/ .user-name {
                    span {
                        font-size: 17px;
                        line-height: 17px;
                    }
                }
            }

            .more-options {
                cursor: pointer;
            }
        }

        .comment-text {
            p {
                font-size: 16px;
                line-height: 21px;
                white-space: pre-line;

                /deep/ a {
                    color: $primary-color;
                }
            }

            textarea {
                width: 100%;
                background-color: transparent;
                font-size: 16px;
                line-height: 16px;
                border: 0;
                color: rgba(235,235,245,0.6);
                padding-top: 8px;
                height: 100%;
                border: 1px solid #3A3A3C;
                border-radius: 8px;
                padding: 10px;

                &::placeholder {
                    color: white;
                }

                &:focus {
                    outline: 0;
                }
            }

            .edit-actions {
                display: flex;
                justify-content: flex-end;
                margin-top: 15px;
                margin-bottom: 25px;

                .cancel {
                    color: $primary-color;

                    &:focus {
                        outline: 0;
                        box-shadow: none;
                    }
                }
            }
        }

        .comment-footer {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            .left-side-footer {
                display: flex;
                align-items: center;

                > * {
                    font-size: 14px;
                    line-height: 15px;
                    color: rgba(235,235,245,0.6);
                }

                .comment-reply {
                    background-color: transparent;
                    border: 0;
                    padding: 0;
                    margin-left: 10px;

                    &:focus {
                        outline: 0;
                    }
                }
            }

            .right-side-footer {
                display: flex;
                align-items: center;

                .like-button {
                    cursor: pointer;
                    opacity: .7;
                    font-size: 13px;
                    display: flex;
                    align-items: center;

                    span {
                        font-size: 15px;
                    }

                    img {
                        width: 18px;
                    }

                    &.is-liked {
                        span {
                            color: $primary-color;
                        }
                    }
                }
            }
        }

        .comment-reply-box {
            margin-top: 15px;

            textarea {
                width: 100%;
                background-color: transparent;
                font-size: 16px;
                line-height: 16px;
                border: 0;
                color: rgba(235,235,245,0.6);
                padding-top: 8px;
                height: 100%;
                border: 1px solid #3A3A3C;
                border-radius: 8px;
                padding: 10px;

                &::placeholder {
                    color: white;
                }

                &:focus {
                    outline: 0;
                }
            }

            .reply-box-actions {
                display: flex;
                justify-content: flex-end;
                margin-top: 15px;

                .cancel {
                    color: $primary-color;

                    &:focus {
                        outline: 0;
                        box-shadow: none;
                    }
                }
            }
        }

        .replies-toggle {
            background-color: transparent;
            border: 0;
            padding: 0;
            color: $primary-color;
            font-size: 14px;
            margin-top: 30px;

            &:focus {
                outline: 0;
            }
        }
    }

    .user-picture-container {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            height: 0%;
            width: 1px;
            background-color: rgba(84, 84, 88, 0.65);
            left: 14px;
            transition: height .5s;
        }
    }

    &.are-replies-expanded {
        .user-picture-container {
            &::before {
                height: 100%;
            }
        }
    }

    &.is-reply {
        padding: 15px 0;

        /deep/ .user-picture {
            position: absolute;
            margin-top: 5px;

            figure {
                width: 20px;
                height: 20px;
            }
        }

        .user-name {
            padding-left: 30px;
        }

        &:not(.are-replies-expanded) {
            .user-picture-container {
                &::before {
                    height: 0;
                }
            }
        }
    }

    &.highlight-comment {
        .comment-text {
            animation: blinkingText .5s;
            animation-iteration-count: 3;
        }
    }

    .replies-list {
        padding-top: 15px;

        .comment:last-child {
            padding-bottom: 0;
        }
    }
}

.replies-animation-item {
    display: inline-block;
}
.replies-animation-enter-active, .replies-animation-leave-active {
    transition: all .5s;
}
.replies-animation-enter, .replies-animation-leave-to {
    opacity: 0;
    transform: translateY(-30px);
}

@keyframes blinkingText {
    0% { opacity: 1;}
    50% { opacity: .25;}
    100% { opacity: 1;}
}
</style>
